import React from "react";
import SocialSharing from "../components/sharing";
import SocialLoging from "../components/socialLogin";
import Rate from "../components/data/rates";
import SimpleLocalize from "../SimpleLocalize";
import {FormattedMessage} from "react-intl";
import LinkTranslated from "../LinkTranslated";
import { GetRequestAuth, PostRequest } from "../services/api/backend";

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};
class JoSandbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        openInterest: [],
        openInterestLoaded: false
    };
  }

  componentDidMount() {
    console.log("gogoblock backend call");
    GetRequestAuth("user", "43|Nn7plOu3xIjQbyS9n7ESk4dCGFLq7naKMhLBH0Pa")
      .then(res => {
        console.log("result GetRequestAuth", res);
      }).catch((err) => {
        console.log("error GetRequestAuth", err);
    });

    console.log("gogoblock backend call");
    const data = {
      endpoint: "/futures/openInterest?symbol=BTC&interval=0"
    }
    PostRequest("coinglass/auth", data)
      .then(res => {
        console.log("result GetRequestAuthCoinglass", res.data.data);
        this.setState({
            openInterest: res.data.data,
            openInterestLoaded: true
        });
      }).catch((err) => {
        console.log("error GetRequestAuthCoinglass", err);
    });
  }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }


  render() {
    return (
      <>
        {/*
          TODO Ken You can find social related functions here
          ```
        */}
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          Social Sharing Component
          <SocialSharing url="https://gogoblock.io"/>
        </div>

        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          Social Login Component
          <SocialLoging />
        </div>

        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          Rate component
          BTC USDT <Rate symbol="btcusdt" />
        </div>

        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          Translation
          <SimpleLocalize {...this.props}>
            <h1>
              <FormattedMessage
                id="hello"
                defaultMessage="Hello World!"
              />
            </h1>

            <p>
              <FormattedMessage
                id="welcome-on-our-website"
                defaultMessage="Welcome on our multi-language website"
              />
            </p>

            <LinkTranslated
              className="btn btn-link"
              href="/about"
            >
              <FormattedMessage
                id="learn-more-about-us"
                defaultMessage="Learn more about us"
              />
            </LinkTranslated>


            <ul>
              <li><a href="/">English (Default)</a></li>
              <li><a href="/pl/">Polish</a></li>
              <li><a href="/es/">Spanish</a></li>
            </ul>

          </SimpleLocalize>
        </div>

        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">

        <div>
            {!this.state.openInterestLoaded ? (
                <div>Loading open interest ...</div>
            ) : (
                <div className = "App">
                <h1> Open Interest </h1>  {
                        this.state.openInterest.map((key, i) => ( 
                            <ol key = { i } >
                            Exchange: { key.exchangeName }, 
                            Open Interest: { key.openInterest }
                        </ol>
                        ))
                    }
                </div>
            )}
        </div>

        </div>

      </>
    );
  }
}
export default JoSandbox;