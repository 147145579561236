import React from "react";
import { Link, navigate } from "gatsby"
import Axios from "axios"

const baseUrl = "https://api.gogoblock.io/api/"
//const baseUrl = "http://127.0.0.1:8001/api/"

// Authentified GET request
// Use this endpoint to call gogoblock api using GET and Token
const GetRequestAuth = (endpoint, token) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    const url = baseUrl + endpoint;
    return Axios.get(url, config);
};
  
// Public GET request
// Use this endpoint to call gogoblock api using GET without Token
const GetRequest = async (endpoint) => {
    const url = baseUrl + endpoint;
    return Axios.get(url);
};

// Authentified POST request
// Use this endpoint to call gogoblock api using POST and Token
const PostRequestAuth = async (endpoint, token, data) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const url = baseUrl + endpoint;
    return Axios.post(url, data, config);
};

// Public POST request
// Use this endpoint to call gogoblock api using POST and Token
const PostRequest = async (endpoint, data) => {
    const url = baseUrl + endpoint;
    return Axios.post(url, data);
};

export {
    GetRequestAuth,
    GetRequest,
    PostRequestAuth,
    PostRequest
};